import { n as normalizeComponent } from "./index.js";
import { PROMOTIONAL_PLANNING_PROMO_PANDL } from "@kraftheinz/common";
import "numeral";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { staticClass: "modal-edit-pl-line", attrs: { "custom-url": "" + _vm.customApiUrl + _vm.plLineId, "entity-id": _vm.plLineId, "is-fetch-entity-by-id": true, "last-modified-by-name": true, "title": "Edit P&L Line" } }, [_c("text-input", { key: "code", attrs: { "disabled": true, "label": "Discount Code", "placeholder": "Discount Code", "rules": "required|alpha_dash", "span": 12 } }), _c("checkbox-input", { key: "extract", attrs: { "class-name": "modal-add-plline__extract", "label": "Extract", "span": 12 } }), _c("text-input", { key: "description", attrs: { "label": "Discount Description", "placeholder": "Discount Description", "max-length": 255, "rules": "required", "span": 12 } }), _c("select-input", { key: "line", attrs: { "label": "Line", "reference": "promotion-planning.common.lines", "placeholder": "Line", "source": "code", "source-label": "description", "rules": "required", "span": 12 } })], 1);
};
var staticRenderFns$1 = [];
var EditPlLine_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "EditPlLine",
  data() {
    const { id } = this.$route.params;
    return {
      plLineId: id,
      customApiUrl: `${apiUrl$1}/promotion-planning/pllines/`
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "2ab4cdc6", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditPlLine = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-pl-line" }, [_c("resource", { attrs: { "name": "promotion-planning.common.lines", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.pllines", "api-url": _vm.apiUrl, "redirect-route": "/promotion-planning/pl-lines", "page": _vm.page } }, [_c("edit-pl-line")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditPlLine
  },
  data() {
    return {
      apiUrl,
      page: PROMOTIONAL_PLANNING_PROMO_PANDL
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
